:root {
    --primary-color: #198754;
    --secondary-color: #03624C;
    --auxiliary-color: #0DCB7A;
  
    --header-link-color: #0DCB7A;
  
    --recent-icons-background: #AACDCA;
    --recent-icons-color: #000;
    --recent-icons-border: #AACDCA;
  
    --pix-keys-manage-color: #0DCB7A;
    --pix-keys-manage-button-background: transparent;
    --pix-keys-manage-button-color: #03624C;
    --pix-keys-manage-button-border: #03624C;
  
    --pix-modal-cancel-button-background: transparent;
    --pix-modal-cancel-button-color: #03624C;
    --pix-modal-cancel-button-border: #03624C;
  
    --pix-modal-confirm-button-background: #03624C;
    --pix-modal-confirm-button-color: #FFFFFF;
    --pix-modal-confirm-button-border: #03624C;
  
    --pix-transfer-confirm-button-background: #03624C;
    --pix-transfer-confirm-button-color: #FFFFFF;
    --pix-transfer-confirm-button-border: #03624C;
  
    --pix-transfer-contact-button-background: transparent;
    --pix-transfer-contact-button-color: #03624C;
    --pix-transfer-contact-button-border: #03624C;
  
    --pix-transfer-modal-cancel-button-background: transparent;
    --pix-transfer-modal-cancel-button-color: #03624C;
    --pix-transfer-modal-cancel-button-border: #03624C;
  
    --pix-transfer-modal-confirm-button-background: #03624C;
    --pix-transfer-modal-confirm-button-color: #FFFFFF;
    --pix-transfer-modal-confirm-button-border: #03624C;
  
    --pix-transfer-modal-input-background: #ffffff;
    --pix-transfer-modal-input-color: #000;
    --pix-transfer-modal-input-border: #000;
  
    --pix-limits-cancel-button-background: transparent;
    --pix-limits-cancel-button-color: #03624C;
    --pix-limits-cancel-button-border: #03624C;
  
    --pix-limits-confirm-button-background: #03624C;
    --pix-limits-confirm-button-color: #FFFFFF;
    --pix-limits-confirm-button-border: #03624C;
  
    --share-receipt-button-background: transparent;
    --share-receipt-button-color: #03624C;
    --share-receipt-button-border: #03624C;
  
    --ted-page-confirm-button-background: #03624C;
    --ted-page-confirm-button-color: #FFFFFF;
    --ted-page-confirm-button-border: #03624C;
  
    --ted-transfer-cancel-button-background: transparent;
    --ted-transfer-cancel-button-color: #03624C;
    --ted-transfer-cancel-button-border: #03624C;
  
    --ted-transfer-confirm-button-background: #03624C;
    --ted-transfer-confirm-button-color: #FFFFFF;
    --ted-transfer-confirm-button-border: #03624C;
  
    --pay-ticket-page-button-background: #20C997;
    --pay-ticket-page-button-color: #000;
    --pay-ticket-page-button-border: #000;
  
    --generate-ticket-page-button-background: transparent;
    --generate-ticket-page-button-color: #000;
    --generate-ticket-page-button-border: #000;
  
    --cancel-ticket-button-background: transparent;
    --cancel-ticket-button-color: #03624C;
    --cancel-ticket-button-border: #03624C;
  
    --confirm-ticket-button-background: #03624C;
    --confirm-ticket-button-color: #FFFFFF;
    --confirm-ticket-button-border: #03624C;
  
    --generate-ticket-select-background: #FFFFFF;
    --generate-ticket-select-color: #000;
    --generate-ticket-select-border: #000;
  
    --pay-ticket-cancel-button-background: transparent;
    --pay-ticket-cancel-button-color: #03624C;
    --pay-ticket-cancel-button-border: #03624C;
  
    --pay-ticket-confirm-button-background: #03624C;
    --pay-ticket-confirm-button-color: #FFFFFF;
    --pay-ticket-confirm-button-border: #03624C;
  
    --delete-photo-button-background: transparent;
    --delete-photo-button-color: #0DCB7A;
    --delete-photo-button-border: none;
  
    --update-info-settings-button-background: transparent;
    --update-info-settings-button-color: #03624C;
    --update-info-settings-button-border: #03624C;
  
    --change-password-button-background: #03624C;
    --change-password-button-color: #FFFFFF;
    --change-password-button-border: #03624C;
  
    --change-password-modal-button-background: #03624C;
    --change-password-modal-button-color: #FFFFFF;
    --change-password-modal-button-border: #03624C;
  
    --checkbox-border: #000;
    --checkbox-color: #ffffff;
  
    --bg-color: #FFFFFF;
    --bg-secondary-color: #042222;
    --bg-auxiliary-color: #dedfe0;
  
    --bg-circle-color: #FFFFFF;
    --bg-circle-hover: #31A886;
    --bg-circle-text-color: #000;
    --bg-circle-text-color-hover: #000;
  
    --bg-modal-color: #FFFFFF;
    --bg-input-color: #FFFFFF;
  
    --dark-color: #3F3F3F;
  
    --text-primary: #121212;
    --text-secondary: #121212;
    --text-gray: #4F4F4F;
  
    --input-color: #121212;
    --menu-active-icon-color: #0DCB7A;
    --info-color: #198754;
  
    --disabled-color: #6c757d;
    --card-color: #edeef0;
  
    --modal-border-color: #ADB5BD;
    --border-color: #FFFFFF;

  --card-button-navigation-color:#198754;
  --card-button-color:#198754;
  --card-button-border-color:#198754;
  --card-bg-icon-color:#198754;
  --card-text-primary-color:#198754;
  --card-bg-primary-color:#198754;
  
  }
  